<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-8 col-sm-12 col-lg-8 col-xs-12">
                <h4>
                  Create Bulk Notification
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                <router-link :to="{name:'bulk-notification'}">
                  Bulk Notification
                </router-link>
                \
               Send
              </div>

              <v-row class="text-center">
                <v-col cols="12" class="text-center">

                  <div class="card-body">
                    <v-form class="form" id="kt_password_change_form" @submit.prevent="getUsers">
                      <div class="form-group row">
                        <div class="col-lg-2">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.type"
                              :items="user_types"
                              label="Send To"
                              item-value="value"
                              item-text="name"
                          ></v-select>

                        </div>
                        <div class="col-lg-2">
                          <v-text-field
                              class="form-control"
                              v-model="search.name"
                              label="Name, Email, Phone, Symbol No"
                              outlined
                              dense>
                          </v-text-field>
                        </div>
                        <div class="col-lg-2" v-if="search.type=='student'">
                          <v-select
                              :items="levels"
                              class="form-control"
                              v-model="search.level_id"
                              label="Level"
                              item-value="id"
                              item-text="title"
                              outlined
                              @change="getPrograms"
                              dense>
                          </v-select>
                        </div>


                        <div class="col-lg-2" v-if="search.type=='student'">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.program_id"
                              :items="programs"
                              label="Programs"
                              item-value="id"
                              item-text="title"
                              @change="getGrades"
                          >
                          </v-select>

                        </div>

                        <div class="col-lg-2" v-if="search.type=='student'">
                          <v-select
                              :items="academicYears"
                              class="form-control"
                              v-model="search.academic_year_id"
                              label="Academic Year"
                              item-value="id"
                              item-text="year"
                              outlined
                              dense>
                          </v-select>
                        </div>
                        <div class="col-lg-2" v-if="search.type=='student'">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.grade_id"
                              :items="gradesLevels"
                              label="Semester/Year"
                              item-value="id"
                              item-text="title"
                              @change="getAcademicClasses"
                          ></v-select>

                        </div>
                        <div class="col-lg-2" v-if="search.type=='student'">
                          <v-select
                              outlined
                              dense
                              class="form-control"
                              v-model="search.class_id"
                              :items="academic_classes"
                              label="Class Name"
                              item-value="id"
                              item-text="title"
                          ></v-select>

                        </div>

                        <div class="col-lg-2">
                          <v-btn
                              class="text-white btn btn-primary"
                              depressed
                              @click="getUsers()"
                              :loading="isBusy"
                          >Search
                          </v-btn>
                          <v-btn
                              class="ml-2 text-white btn btn-danger"
                              depressed
                              @click="resetFilter()"
                              :loading="isBusy"
                          >Reset
                          </v-btn>
                        </div>
<!--                        <pre>{{ search }}</pre>-->

                      </div>
                    </v-form>
                  </div>

                </v-col>
                <v-col cols="12" v-if="selected.length>0">
                  <v-card flat color="grey lighten-4">
                    <v-toolbar flat color="grey lighten-4">
                      <v-toolbar-title>Total Selected : {{ selected.length }}</v-toolbar-title>
                      <v-toolbar-title class="ml-5"> Actions :</v-toolbar-title>
                      <v-btn depressed @click="openCreateNotificationDialog">
                        <v-icon>assignment_ind</v-icon>
                        Create
                      </v-btn>
                      <v-btn depressed @click="resetSelected">
                        Reset
                      </v-btn>
                    </v-toolbar>
                  </v-card>
                </v-col>
              </v-row>
              <div class="col-12" v-if=" selected && selected.length>0">
                <table class="table table-stripe">
                  <tr v-for="(sel, i) of selected" :key="i">
                    <td>{{ sel.full_name }}</td>
                    <td>{{ sel.email }}</td>
                    <td>{{ sel.phone }}</td>
                    <td>{{ (sel.type && sel.type.length > 0) ? sel.type.join(",") : '' }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-12">
                <table class="table table-stripe">
                  <thead>
                  <th>
                    <v-checkbox @change="selectAll()" v-model="checkAll" outlined dense label="Full Name"></v-checkbox>
                  </th>
                  <th>Image</th>
                  <th v-if="search.type.includes('student')">Symbol Number</th>
                  <th>Full name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th v-if="search.type.includes('student')">Program</th>
                  <th v-if="search.type.includes('student')">Semester/Year</th>
                  <th v-if="search.type.includes('student')">Class</th>
                  <th v-if="search.type.includes('student')">Status</th>
                  <th v-if="search.type.includes('student')">Action</th>
                  </thead>
                  <tbody>
                  <tr v-for="(user, index) of users" :key="index">
                    <td>
                      <v-checkbox v-model="selected_id" :value="user.id" :checked="selected_id.includes(user.id)"
                                  @change="addToSelected"
                                  outlined
                                  dense v-bind:label="(user.full_name)?user.full_name:null"></v-checkbox>

                    </td>
                    <td>
                      <div class="symbol-label">
                        <img v-if="user.photo"
                             :src="user.image_path.thumb"
                             class="cursor-pointer" alt=""
                             @click="changeImage(user)"
                             style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                        @click="changeImage(user)"> {{
                                                        (user.full_name) ? user.full_name.charAt(0) : null
                                                      }} </span>
                                                </span>
                      </div>
                    </td>
                    <td>{{ user.setting ? user.setting.symbol_no : "N/A" }}</td>
                    <td>{{ user.full_name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td>{{
                        user.setting ? user.setting.program_title : "N/A"
                      }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.current_semester_title
                            : "N/A"
                      }}
                    </td>
                    <td>{{
                        user.setting ? user.setting.current_class_title :
                            "N/A"
                      }}
                    </td>
                    <td>
                      <span
                          :class="user.is_active?'badge badge-success':'badge badge-danger'">{{
                          user.is_active ? 'Active' : 'Inactive'
                        }}</span>
                    </td>
                    <td>
                      <div class="kt-widget__toolbar">

                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown"
                            no-caret
                            right
                            no-flip
                            text="Actions"

                        >
                          <template v-slot:button-content>
                            <a href="#" class="" data-toggle="dropdown">
                              <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                              <!--begin::Svg Icon-->
                              <i class="flaticon-more-1"></i>
                              <!--end::Svg Icon-->
                              <!--              </span>-->
                            </a>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">


                            <b-dropdown-text tag="div" class="navi-item">

                              <router-link v-if="user.type"
                                           :to="{name:'students-summary', params:{id:user.id, type:user.type[0]}}">
                                <a href="#" class="navi-link"
                                >
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </router-link>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </div>

                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="col-12 text-right" v-if="users.length">
                  <b-pagination
                      @input="getUsers"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                  ></b-pagination>
                </div>
                <v-dialog v-model="dialog" max-width="600">
                  <v-card>
                    <v-card-title>
                      Notification
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea v-model="notification.message" outlined dense label="Message"></v-textarea>
                          <span class="text-danger" v-if="$v.notification.message.$error">Message is required</span>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field v-model="notification.subject" outlined dense label="Subject"></v-text-field>
                          <span class="text-danger" v-if="$v.notification.subject.$error">Subject is required</span>
                        </v-col>
                       <v-col cols="6">
                          <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  dense
                                  outlined
                                  v-model="notification.send_date"
                                  v-bind="attrs"
                                  v-on="on"
                                  label="Scheduled for"
                                  class="form-control"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="notification.send_date"
                                header-menu="primary"
                            ></v-date-picker>
                          </v-menu>
                          <span class="text-danger" v-if="$v.notification.send_date.$error">Sending date is required</span>
                        </v-col>
                        <v-col cols="6">
                          <v-menu
                              ref="menu"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="notification.send_at"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  outlined dense
                                  v-model="notification.send_at"
                                  label="Scheduled for"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="menu2"
                                v-model="notification.send_at"
                                full-width
                                @click:minute="$refs.menu.save(notification.send_at)"
                            ></v-time-picker>
                          </v-menu>
<!--                          <span class="text-danger" v-if="$v.notification.send_at.$error">Sending date is required</span>-->
                        </v-col>
                        <v-col cols="12 ">
                          <v-radio-group
                              v-model="notification.type"
                              row
                          > <v-radio
                              label="Email"
                              value="bulk_email"
                          ></v-radio>
                            <v-radio
                                label="SMS"
                                value="bulk_sms"
                            ></v-radio>

                          </v-radio-group>
                        </v-col>
                      </v-row>

                    </v-card-text>
                    <v-card-actions>
                      <div class="row">
                        <div class="col-12 ">
                      <v-btn
                          color="red"
                          class="text-gray  ml-2  btn btn-standard float-right"
                          depressed
                          @click="dialog=false"
                      >Cancel
                      </v-btn>
                      <v-btn
                          class="text-white ml-2 btn btn-primary float-right"
                          depressed
                          @click="createNotification"
                          :loading="isBusy"
                      >Save
                      </v-btn>
                        </div>
                      </div>
                    </v-card-actions>

                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import PrinterService from "@/core/services/printer/PrinterService";
import {required} from "vuelidate/lib/validators";
import {API_URL} from "@/core/config";
import EmailNotificationService from "@/core/services/email-notification/EmailNotificationService";

const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const emailService = new EmailNotificationService();

const printerService = new PrinterService();
export default {
  name: "users",
  components: {},
  validations: {
    notification: {
      message: {required},
      subject: {required},
      send_date: {required},
    }
  },
  data() {
    return {
      import_excel: false,
      dob_bs: null,
      checkAll: false,
      isLoading: false,
      dialog: false,
      menu: false,
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      excel_file: null,
      isBusy: false,
      uploadDialog: false,
      studentCards: [],
      user_types: [
        {name: "Student", value: 'student'},
        {name: 'Teacher', value: 'teacher'},
        {name: 'Staff', value: 'staff'},
      ],
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      search: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        type: 'student',
        id_type: 'new',
        limit: 1000,

      },
      notification: {
        message: null,
        subject: null,
        send_date: null,
        send_at: null,
        type: 'bulk_email',
        notification_type: 'group',
      },
      users: [],
      selected: [],
      previously_selected_id: [],
      selected_id: [],
      studentList: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      printers: [],
      levels: [],
      levelId: null,
      page: null,
      total: null,
      perPage: null
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    if (this.$route.query.first_name) {
      this.search = this.$route.query;
    }
    this.getLevels();
    this.getAcademicYears();
  },
  methods: {
    getUsers() {
      userService.paginate(this.search).then(response => {
        this.checkAll = false
        this.selected_id = this.previously_selected_id
        this.users = response.data.data
      })
    },
    getPrinters() {
      printerService.paginate().then(response => {
        this.printers = response.data.data;
      });
    },
    resetFilter() {
      this.search = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: '',
        type: 'student',
        limit: 80
      }
      this.users = [];
      // this.getUsers();


    },
    changeImage(user) {
      this.user = user;
      this.uploadDialog = true;
    },

    removeStudentFromList(index) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.studentList.splice(index, 1);
          }
        }
      });

    },

    selectAll() {
      if (!this.checkAll) {
        this.selected = []
      } else {
        this.users.forEach(user => {
          this.selected_id.push(user.id)
        })
      }
      this.selected_id.map(id => {
        this.previously_selected_id.push(id);
      })
      this.addToSelected()
    },

    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;

      });
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.getSlider();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    }, resetSelected() {
      this.selected = []
      this.selected_id = []
      this.checkAll = false

    }, addToSelected() {
      this.previously_selected_id = this.selected_id
      // this.selected_id.map(id=>{
      //   this.previously_selected_id
      // })
      let arr = this.previously_selected_id
      this.previously_selected_id = arr.filter(function (item, pos) {
        return arr.indexOf(item) == pos;
      });
      userService.getAllUserByIds({ids: this.previously_selected_id}).then(response => {
        this.selected = response.data
      })
    }, openCreateNotificationDialog() {
      this.dialog = true
    }, createNotification() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      } else {
        this.notification.reference_id = this.previously_selected_id
        emailService.store(this.notification).then(response => {
          this.$snotify.success("Notification Information added")
          this.$router.push('bulk-notification');
        })
      }
    }
  }
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
